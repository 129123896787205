import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../component/Footer";
import Header from "../component/Header";
import OrderWithModal from "../Modal/OrderWithModal";
const MainLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <OrderWithModal />
    </>
  );
};

export default MainLayout;
