import SvgIcon from "@mui/material/SvgIcon";

const TokopediaIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      transform="scale(1.8)"
    >
      <path d="M620.4 326.4c-5.6-56.4-51.8-100.3-108-100.3-56.1 0-102.3 43.5-108.7 99.6l29.1 2.3c5.2-40.5 38.6-71.8 79.4-71.8 40.8 0 74.6 31.8 78.9 72.8l29.3-2.6zm94.3-6.5c-32 0-63.9-1.7-95.8 1.1l-29.1 2.6c-25.9 2.3-54.3 8.9-77.6 20.9-24.8-12.9-50.2-19.8-77.9-22l-29.1-2.3c-32-2.6-63.8-.4-95.8-.4h-17.5v418.3h294.4c80.4 0 146.1-67.7 146.1-150.5V319.9h-17.7zM331.4 496.1c0-48.6 38.3-88.1 85.5-88.1s85.5 39.4 85.5 88.1c0 48.6-38.3 88.1-85.5 88.1-47.3 0-85.5-39.5-85.5-88.1zM512 632.9c-12.7-13.9-25.4-27.7-38.1-41.5 8-14.2 23.1-21.3 38.1-21.2 15 0 30.1 7 38.1 21.2-12.7 13.8-25.4 27.6-38.1 41.5zm95.1-48.7c-47.2 0-85.5-39.4-85.5-88.1 0-48.6 38.3-88.1 85.5-88.1s85.5 39.4 85.5 88.1c0 48.6-38.3 88.1-85.5 88.1zM427.6 441.4c30.5 0 55.2 25.5 55.2 56.8 0 31.4-24.7 56.9-55.2 56.9-30.5 0-55.2-25.5-55.2-56.9 0-10.5 2.8-20.3 7.6-28.7 3.9 10 13.5 17.1 24.7 17.1 14.7 0 26.6-12.2 26.6-27.4 0-6.8-2.4-13-6.4-17.8h2.7zm168.8 0c30.5 0 55.2 25.5 55.2 56.8 0 31.4-24.7 56.9-55.2 56.9-30.5 0-55.2-25.5-55.2-56.9 0-10.5 2.7-20.3 7.5-28.7 4 10 13.5 17.1 24.7 17.1 14.7 0 26.6-12.2 26.6-27.4 0-6.8-2.4-13-6.4-17.8h2.8z" />
    </SvgIcon>
  );
};
export default TokopediaIcon;
