import React, { useState } from "react";
import CatalogContent from "../component/catalog/CatalogContent";
import Header from "../component/Header";
import CatalogModal from "../Modal/CatalogModal";

const Catalog = () => {
  const [selectedCatalog, setSelectedCatalog] = useState(false);
  return (
    <>
      <CatalogContent setSelectedCatalog={setSelectedCatalog} />
      <CatalogModal
        selectedCatalog={selectedCatalog}
        setSelectedCatalog={setSelectedCatalog}
      />
    </>
  );
};

export default Catalog;
