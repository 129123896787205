import React, { createContext, useContext, useState } from "react";
const ModalContext = createContext();
const SetModalContext = createContext();

export const useOrderWithModalOpen = () => {
  return useContext(ModalContext);
};
export const useSetOrderWithModalOpen = () => {
  return useContext(SetModalContext);
};
export const OrderWithModaProfider = ({ children }) => {
  const [orderWithModalOpen, setOrderWithModalOpen] = useState(false);
  return (
    <ModalContext.Provider value={orderWithModalOpen}>
      <SetModalContext.Provider value={setOrderWithModalOpen}>
        {children}
      </SetModalContext.Provider>
    </ModalContext.Provider>
  );
};
