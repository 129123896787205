import ShopeeIcon from "../icons/ShopeeIcon";
import TokopediaIcon from "../icons/TokopediaIcon";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Instagram from "@mui/icons-material/Instagram";
import Facebook from "@mui/icons-material/Facebook";
import GoogleMapsIcon from "../icons/GoogleMapsIcon";
const { batam, surabaya, jakarta } = {
  batam: {
    shopee: "https://shopee.co.id/deplafon_official",
    tokopedia: "",
    whatsApp: "https://wa.me/6282268598464",
    instagram: "https://instagram.com/deplafon.official?igshid=YmMyMTA2M2Y=",
    facebook: "https://www.facebook.com/deplafongallery/",
    googleMaps: "https://goo.gl/maps/LJKx1Y6V1KoGeyUY7",
  },
  surabaya: {
    shopee: "https://shopee.co.id/deplafon",
    tokopedia: "https://www.tokopedia.com/deplafon",
    whatsApp: "https://wa.me/6282288669716",
    instagram: "",
    facebook: "",
    googleMaps: "https://maps.app.goo.gl/ZV1fm7a1kWgkD6n38",
  },
  jakarta: {
    shopee: "",
    tokopedia: "",
    whatsApp: "",
    instagram: "",
    facebook: "",
    googleMaps: "https://maps.app.goo.gl/sN8MVyiJPgUNqjZKA",
  },
};

const socialMediaLink = (location) => {
  const linkArray = [];
  if (location.shopee)
    linkArray.push({
      icon: <ShopeeIcon />,
      color: "rgba(241,89,42,255)",
      href: `${location.shopee}`,
    });
  if (location.tokopedia)
    linkArray.push({
      icon: <TokopediaIcon />,
      color: "rgba(78,157,78,255)",
      href: `${location.tokopedia}`,
    });
  if (location.whatsApp)
    linkArray.push({
      icon: <WhatsApp />,
      color: "rgba(27,215,65,255)",
      href: `${location.whatsApp}`,
    });
  if (location.instagram)
    linkArray.push({
      icon: <Instagram />,
      color: "rgb(252,4,154)",
      href: `${location.instagram}`,
    });
  if (location.facebook)
    linkArray.push({
      icon: <Facebook />,
      color: "rgba(65,103,178,255)",
      href: `${location.facebook}`,
    });

  return linkArray;
};
const googleMapsLink = (location) => {
  if (location.googleMaps)
    return {
      icon: <GoogleMapsIcon />,
      href: `${location.googleMaps}`,
    };
};
export const socialMediaBatam = socialMediaLink(batam);
export const socialMediaJakarta = socialMediaLink(jakarta);
export const socialMediaSurabaya = socialMediaLink(surabaya);
export const googleMapsBatam = googleMapsLink(batam);
export const googleMapsJakarta = googleMapsLink(jakarta);
export const googleMapsSurabaya = googleMapsLink(surabaya);
