import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useSetOrderWithModalOpen } from "../Context/OrderWithModalContext";
import { Link } from "react-router-dom";
import deplafonIcon from "../assets/image/icon/logo deplafon.jpg";

function Header() {
  const modalHandle = useSetOrderWithModalOpen();
  const [anchorElNav, setAnchorElNav] = useState();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const [ref, inView] = useInView({ rootMargin: "30px" });
  const [isScrollDown, setIsScrollDown] = useState(false);
  useEffect(() => {
    if (inView === true) {
      setIsScrollDown(true);
    } else {
      setIsScrollDown(false);
    }
  }, [inView]);
  return (
    <>
      <AppBar
        position="fixed"
        color="inherit"
        elevation={isScrollDown ? 0 : 2}
        sx={{
          bgcolor: isScrollDown ? "transparent" : "common.white",
          top: 0,
          transition: "all 0.3s ease",
        }}
      >
        <Container>
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, height: "32px" }}>
              <Link to="/">
                <Box
                  component="img"
                  src={deplafonIcon}
                  alt="logo deplafon"
                  height="100%"
                />
              </Link>
            </Box>

            <Box sx={{ flexGrow: 0, display: { xs: "none", sm: "flex" } }}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    mx: { md: 1, sm: 0 },
                    display: "block",
                    textTransform: "capitalize",
                  }}
                >
                  Home
                </Button>
              </Link>

              <Link to="catalog" style={{ textDecoration: "none" }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    mx: { md: 1, sm: 0 },
                    display: "block",
                    textTransform: "capitalize",
                  }}
                >
                  Catalog
                </Button>
              </Link>

              <Button
                variant="contained"
                size="small"
                sx={{
                  my: 2,
                  mx: 1,
                  display: "block",
                }}
                onClick={() => modalHandle(true)}
              >
                ORDER NOW
              </Button>
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: "flex", sm: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <Link to="/" style={{ textDecoration: "none" }}>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Home</Typography>
                  </MenuItem>
                </Link>

                <Link to="catalog" style={{ textDecoration: "none" }}>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Catalog</Typography>
                  </MenuItem>
                </Link>

                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    my: 2,
                    mx: 1,
                    display: "block",
                  }}
                  onClick={() => modalHandle(true)}
                >
                  ORDER NOW
                </Button>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        sx={{
          bgcolor: "grey.300",
          height: "1px",
        }}
        ref={ref}
      />
    </>
  );
}
export default Header;
