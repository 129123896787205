import SvgIcon from "@mui/material/SvgIcon";

const ShopeeIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 48 48"
      transform="scale(0.9)"
    >
      <path d="M 24 1 C 19.015304 1 15.187203 5.491076 14.589844 11 L 5.0605469 11 C 4.4905469 11 4.0305469 11.480547 4.0605469 12.060547 L 5.7792969 41.230469 C 5.8992969 43.350469 7.6495312 45 9.7695312 45 L 38.230469 45 C 40.350469 45 42.100703 43.350469 42.220703 41.230469 L 43.939453 12.060547 C 43.969453 11.480547 43.509453 11 42.939453 11 L 33.410156 11 C 32.812797 5.491076 28.984696 1 24 1 z M 24 4 C 27.044271 4 29.834574 6.9017187 30.392578 11 L 17.607422 11 C 18.165426 6.9017187 20.955729 4 24 4 z M 24.080078 17.009766 C 27.000078 17.009766 29.300391 18.349922 30.150391 18.919922 L 30.320312 19.029297 C 30.540312 19.179297 30.610703 19.480937 30.470703 19.710938 L 29.460938 21.359375 C 29.380938 21.469375 29.270625 21.559844 29.140625 21.589844 C 29.100625 21.589844 29.069297 21.599609 29.029297 21.599609 C 28.929297 21.599609 28.83 21.569766 28.75 21.509766 L 28.609375 21.419922 C 28.419375 21.289922 28.059219 21.079766 27.949219 21.009766 C 27.219219 20.619766 25.740078 19.960938 24.080078 19.960938 C 21.780078 19.960938 20.109375 21.169844 20.109375 22.839844 C 20.109375 24.389844 21.499453 25.070391 24.439453 26.150391 C 27.479453 27.260391 31.259766 28.639062 31.259766 33.039062 C 31.259766 36.369063 28.039453 38.990234 23.939453 38.990234 C 20.459453 38.990234 17.629609 36.919297 16.849609 36.279297 L 16.730469 36.189453 C 16.530469 36.019453 16.490625 35.729531 16.640625 35.519531 L 17.740234 33.890625 C 17.820234 33.770625 17.949609 33.689922 18.099609 33.669922 L 18.150391 33.669922 C 18.280391 33.669922 18.400234 33.720781 18.490234 33.800781 L 18.619141 33.919922 C 18.639141 33.939922 21.109453 36.039063 23.939453 36.039062 C 26.349453 36.039062 28.310547 34.689062 28.310547 33.039062 C 28.310547 30.929063 26.749922 30.130156 23.419922 28.910156 C 20.639922 27.890156 17.169922 26.619844 17.169922 22.839844 C 17.169922 19.519844 20.140078 17.009766 24.080078 17.009766 z"></path>
    </SvgIcon>
  );
};
export default ShopeeIcon;
