import React from "react";
import Home from "../component/company profile/Home";
import OptionStyle from "../component/company profile/OptionStyle";
import Portfolio from "../component/company profile/Portfolio";
import Services from "../component/company profile/Services";
import SmallCatalog from "../component/company profile/SmallCatalog";
// import Testimony from "../component/company profile/Testimony";
// import FAQ from "../component/company profile/FAQ";
const CompanyProfile = () => {
  return (
    <>
      <Home />
      <Services />
      <OptionStyle />
      <Portfolio />
      <SmallCatalog />
      {/* <Testimony />
      <FAQ /> */}
    </>
  );
};

export default CompanyProfile;
