import "./App.css";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CompanyProfile from "./pages/CompanyProfile";
import { Route, Routes } from "react-router-dom";
import indigo from "@mui/material/colors/indigo";
import MainLayout from "./pages/MainLayout";
import { OrderWithModaProfider } from "./Context/OrderWithModalContext";
import Catalog from "./pages/Catalog";
import ScrollToTop from "./settings/ScrollToTop";
const theme = createTheme({
  palette: {
    primary: {
      light: "#1519EA",
      main: "#0E119C",
      dark: "#090A60",
    },
    custom: {
      background2: indigo[50],
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <OrderWithModaProfider>
        <ScrollToTop />
        <Box className="App">
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<CompanyProfile />} />
              <Route path="catalog" element={<Catalog />} />
            </Route>
          </Routes>
        </Box>
      </OrderWithModaProfider>
    </ThemeProvider>
  );
}

export default App;
