import React from "react";
import Box from "@mui/material/Box";
import Carousel from "react-material-ui-carousel";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container } from "@mui/system";
import { useSetOrderWithModalOpen } from "../../Context/OrderWithModalContext";
import { coverImage, subCoverImage } from "../../assets/image/portfolio";

const Home = () => {
  const modalHandle = useSetOrderWithModalOpen();
  return (
    <Box
      id="home"
      position="relative"
      width="inherit"
      maxHeight="100vh"
      sx={{
        height: { sm: "100vh" },
        maxHeight: { lg: "800px", md: "600px", sm: "500px" },
      }}
    >
      <Container
        sx={{
          maxHeight: "inherit",
          width: "inherit",
          height: "inherit",
          display: "flex",
          flexDirection: { xs: "column-reverse", sm: "row" },
          zIndex: "2",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: { sm: "40%", xs: "100%" },
            height: { sm: "100%" },
            textAlign: { sm: "start", xs: "center" },
            alignItems: { sm: "start", xs: "center" },
            pb: { md: 0, xs: 5 },
            pt: { md: 5, xs: 0 },
          }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          gap={2}
          zIndex={3}
        >
          <Paper
            bgcolor="common.white"
            sx={{
              p: { sm: 1, xs: 1 },
              pl: { sm: 0 },
              width: "max-content",
              boxShadow: { sm: 0, xs: 2 },
              transform: { xs: "translateY(-30%)", sm: "none" },
            }}
          >
            <Typography
              sx={{
                typography: { lg: "h3", sm: "h4", xs: "h6" },
              }}
              component="h1"
            >
              Solusi Plafon yang <b style={{ color: "#1519EA" }}>Inovatif</b>
              <br /> untuk <b style={{ color: "#1519EA" }}>Rumah Impian</b>{" "}
              Anda.
            </Typography>
          </Paper>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ width: { sm: "80%", xs: "100%" } }}
          >
            De Plafon adalah supplier produk seperti Plafon PVC, Wallboard, dan
            Vinyl yang berkualitas tinggi dan ramah lingkungan.
          </Typography>
          <Button variant="contained" onClick={() => modalHandle(true)}>
            ORDER NOW
          </Button>
        </Box>
        <Box
          position="relative"
          sx={{
            width: { sm: "60%", xs: "100%" },
            display: { xs: "flex" },
            justifyContent: { xs: "center" },
            alignItems: { xs: "center" },
            mt: { xs: "56px", md: 0 },
          }}
        >
          <Carousel
            sx={{
              width: "100%",
              position: { sm: "absolute" },
              top: { sm: "50%" },
              left: { sm: "-10%" },
              transform: {
                sm: "translateY(-50%) ",
              },
              zIndex: 2,
              overflow: "visible",
            }}
            navButtonsAlwaysInvisible
            indicatorContainerProps={{ className: "xs-hidden" }}
          >
            {coverImage.map((image) => {
              return (
                <Paper key={image} elevation={3} sx={{ overflow: "hidden" }}>
                  <Box
                    component="img"
                    src={image}
                    width="100%"
                    display="block"
                  />
                </Paper>
              );
            })}
          </Carousel>
          <Paper
            sx={{
              width: "40%",
              overflow: "hidden",
              position: "absolute",
              right: 0,
              top: "50%",
              display: { sm: "block", xs: "none" },
              transform: "translateY(70%)",
            }}
          >
            <Box
              component="img"
              src={subCoverImage}
              width="100%"
              display="block"
            />
          </Paper>
        </Box>
      </Container>
      <Box
        position="absolute"
        top={{ sm: 0 }}
        bottom={{ sm: "unset", xs: 0 }}
        left="0"
        bgcolor="white"
        sx={{
          width: { sm: "40%", xs: "100%" },
          height: "100%",
        }}
      />
    </Box>
  );
};

export default Home;
