import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import React from "react";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const CatalogModal = ({ selectedCatalog, setSelectedCatalog }) => {
  return (
    <Modal open={selectedCatalog} onClose={() => setSelectedCatalog(false)}>
      <Box
        sx={{
          width: "min(70vh , 95%)",
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            pb: 1,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{ width: "100%", display: "block" }}
            component="img"
            src={selectedCatalog.src}
            alt={selectedCatalog.name}
          />
          <Typography textAlign="center" mt={1} display="block">
            {selectedCatalog.name}
          </Typography>
        </Paper>
        <IconButton
          onClick={() => setSelectedCatalog(false)}
          sx={{
            color: "white",
            mt: 1,
            backgroundColor: "rgba(255,255,255,0.2)",
            "&:hover, &.Mui-focusVisible": {
              backgroundColor: "rgba(255,255,255,0.3)",
            },
          }}
        >
          <Close />
        </IconButton>
      </Box>
    </Modal>
  );
};

export default CatalogModal;
