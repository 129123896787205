import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import catalogImageIndex from "../../assets/image/catalog/catalogImageIndex";

const CatalogContent = ({ setSelectedCatalog }) => {
  return (
    <Box sx={{ my: "80px" }}>
      <Container>
        <Stack spacing={2}>
          {catalogImageIndex.map(({ title, caption, items }, i) => {
            return (
              <Box key={i} sx={{ py: 2 }}>
                <Typography variant="h5" textAlign="center" mb={2}>
                  {title}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  textAlign="center"
                  mb={2}
                >
                  {caption}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    gap: 1,
                  }}
                >
                  {items.map(({ name, src }, i) => {
                    return (
                      <Paper
                        key={i}
                        sx={{
                          width: { md: 200, xs: 140 },
                          overflow: "hidden",
                          cursor: "pointer",
                        }}
                        onClick={() => setSelectedCatalog({ name, src })}
                      >
                        <Box
                          sx={{ width: "100%", display: "block" }}
                          component="img"
                          src={src}
                          alt={name}
                        />
                        <Typography textAlign="center" my={1} display="block">
                          {name}
                        </Typography>
                      </Paper>
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </Stack>
      </Container>
    </Box>
  );
};

export default CatalogContent;
