import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Slider from "react-slick";
import Button from "@mui/material/Button";
import smallCatalogImageIndex from "../../assets/image/catalog/smallCatalogImageIndex";
import { Link } from "react-router-dom";
const SmallCatalog = () => {
  const sliderSettings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 5,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "ease",
    swipeToSlide: true,
    focusOnSelect: true,
    arrows: false,
    centerPadding: "30px",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          centerPadding: "10px",
        },
      },
    ],
  };
  return (
    <Box bgcolor="white">
      <Container>
        <Stack
          width="100%"
          height="100%"
          justifyContent="center"
          spacing={3}
          py={5}
        >
          <Typography
            component="h2"
            variant="body1"
            color="text.secondary"
            textAlign="center"
          >
            Catalog
          </Typography>
          <Typography
            component="h3"
            sx={{ typography: { sm: "h5", xs: "h6" } }}
            textAlign="center"
          >
            Beberapa <b style={{ color: "#1519EA" }}>Variasi</b> Plafon Kami
          </Typography>
          <Slider {...sliderSettings}>
            {smallCatalogImageIndex.map((image, i) => {
              return (
                <Box key={i}>
                  <Box
                    component="img"
                    src={image}
                    alt="plafon"
                    display="block"
                    sx={{
                      cursor: "pointer",
                      boxShadow: 2,
                      borderRadius: 2,
                      width: "95%",
                    }}
                  />
                </Box>
              );
            })}
          </Slider>
          <Link
            to="/catalog"
            style={{ textDecoration: "none", alignSelf: "center" }}
          >
            <Button variant="text">SEE MORE</Button>
          </Link>
        </Stack>
      </Container>
    </Box>
  );
};

export default SmallCatalog;
