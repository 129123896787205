import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import {
  useSetOrderWithModalOpen,
  useOrderWithModalOpen,
} from "../Context/OrderWithModalContext";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Close from "@mui/icons-material/Close";
import {
  socialMediaBatam,
  socialMediaSurabaya,
  // socialMediaJakarta,
  googleMapsBatam,
  googleMapsJakarta,
  googleMapsSurabaya,
} from "../data/link";
const IconButtonCustom = ({ customColor, children }) => {
  return (
    <IconButton
      sx={{
        color: `${customColor}`,
        border: `1px solid ${customColor}`,
        "&:hover, &.Mui-focusVisible": {
          backgroundColor: `${customColor}`,
          color: "white",
        },
      }}
    >
      {children}
    </IconButton>
  );
};
const OrderWithModal = () => {
  const modalOpen = useOrderWithModalOpen();
  const modalHandle = useSetOrderWithModalOpen();
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Modal open={modalOpen} onClose={() => modalHandle(false)}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "95%",
          maxWidth: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Stack
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
          direction="row"
          mb={3}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Order With
          </Typography>
          <IconButton onClick={() => modalHandle(false)}>
            <Close />
          </IconButton>
        </Stack>
        <Divider />
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange}>
              <Tab label="Batam" value="1" />
              <Tab label="Surabaya" value="2" />
              <Tab label="Jakarta" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ p: 1 }}>
            <Typography my={2} color="text.secondary">
              Social Media
            </Typography>
            <Stack gap={2} direction="row" mb={3} flexWrap="wrap">
              {socialMediaBatam.map(({ icon, color, href }, i) => {
                return (
                  <a href={href} target="_blank" key={i} rel="noreferrer">
                    <IconButtonCustom customColor={color}>
                      {icon}
                    </IconButtonCustom>
                  </a>
                );
              })}
            </Stack>
            {googleMapsBatam ? (
              <>
                <Typography my={2} color="text.secondary">
                  Location
                </Typography>
                <a
                  href={googleMapsBatam.href}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <Button
                    startIcon={googleMapsBatam.icon}
                    variant="outlined"
                    color="success"
                  >
                    Google Maps
                  </Button>
                </a>
              </>
            ) : null}
          </TabPanel>
          <TabPanel value="2" sx={{ p: 1 }}>
            <Typography my={2} color="text.secondary">
              Social Media
            </Typography>
            <Stack gap={2} direction="row" mb={3} flexWrap="wrap">
              {socialMediaSurabaya.map(({ icon, color, href }, i) => {
                return (
                  <a href={href} target="_blank" key={i} rel="noreferrer">
                    <IconButtonCustom customColor={color}>
                      {icon}
                    </IconButtonCustom>
                  </a>
                );
              })}
            </Stack>

            {googleMapsSurabaya ? (
              <>
                <Typography my={2} color="text.secondary">
                  Location
                </Typography>
                <a
                  href={googleMapsSurabaya.href}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <Button
                    startIcon={googleMapsSurabaya.icon}
                    variant="outlined"
                    color="success"
                  >
                    Google Maps
                  </Button>
                </a>
              </>
            ) : null}
          </TabPanel>
          <TabPanel value="3" sx={{ p: 1 }}>
            {/* <Typography my={2} color="text.secondary">
              Social Media
            </Typography>
            <Stack gap={2} direction="row" mb={3} flexWrap="wrap">
              {socialMediaJakarta.map(({ icon, color, href }, i) => {
                return (
                  <a href={href} target="_blank" key={i} rel="noreferrer">
                    <IconButtonCustom customColor={color}>
                      {icon}
                    </IconButtonCustom>
                  </a>
                );
              })}
            </Stack> */}
            {googleMapsJakarta ? (
              <>
                <Typography my={2} color="text.secondary">
                  Location
                </Typography>
                <a
                  href={googleMapsJakarta.href}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <Button
                    startIcon={googleMapsJakarta.icon}
                    variant="outlined"
                    color="success"
                  >
                    Google Maps
                  </Button>
                </a>
              </>
            ) : null}
          </TabPanel>
        </TabContext>
      </Box>
    </Modal>
  );
};

export default OrderWithModal;
