import JX02S from "./lis/JX02S.jpg";
import JX04 from "./lis/JX04.jpg";
import JX04W from "./lis/JX04W.jpg";
import JX101 from "./lis/JX101.jpg";
import JX101W from "./lis/JX101W.jpg";
import JX301 from "./lis/JX301.jpg";
import JX301W from "./lis/JX301W.jpg";
import JX302 from "./lis/JX302.jpg";
import JX302W from "./lis/JX302W.jpg";
import JX305 from "./lis/JX305.jpg";
import JX305W from "./lis/JX305W.jpg";
import DE812 from "./plafon/DE BROWN SERIES/DE812.jpg";
import DE813 from "./plafon/DE BROWN SERIES/DE813.jpg";
import DE701 from "./plafon/DE PREMIUM/DE701.jpg";
import DE711 from "./plafon/DE PREMIUM/DE711.jpg";
import DE900 from "./plafon/DE PREMIUM/DE900.jpg";
import DE900W from "./plafon/DE PREMIUM/DE900W.jpg";
import DE901 from "./plafon/DE PREMIUM/DE901.jpg";
import DE902 from "./plafon/DE PREMIUM/DE902.jpg";
import DE903 from "./plafon/DE PREMIUM/DE903.jpg";
import DE904 from "./plafon/DE PREMIUM/DE904.jpg";
import DS04 from "./plafon/DS SERIES/DS04.jpg";
import DSJ00 from "./plafon/DSJ/DSJ00.jpg";
import DSJ01 from "./plafon/DSJ/DSJ01.jpg";
import DSJ02 from "./plafon/DSJ/DSJ02.jpg";
import DSJ03 from "./plafon/DSJ/DSJ03.jpg";
import DSJ04 from "./plafon/DSJ/DSJ04.jpg";
import DSJ05 from "./plafon/DSJ/DSJ05.jpg";
import DE800 from "./plafon/WHITE SERIES/DE800.jpg";
import DE801 from "./plafon/WHITE SERIES/DE801.jpg";
import DE802 from "./plafon/WHITE SERIES/DE802.jpg";
import DE803 from "./plafon/WHITE SERIES/DE803.jpg";
import DE805 from "./plafon/WHITE SERIES/DE805.jpg";
import DE806 from "./plafon/WHITE SERIES/DE806.jpg";

const catalogImageIndex = [
  {
    title: "DS SERIES",
    caption: " Panjang 4m. Tebal 7,5mm - 8mm, Lebar 25cm",
    items: [
      { name: "DS00", src: DE800 },
      { name: "DS01", src: DE801 },
      { name: "DS04", src: DS04 },
      { name: "DS05", src: DE805 },
      { name: "DS06", src: DE806 },
    ],
  },

  {
    title: "DE WHITE SERIES",
    caption: "Panjang  6m. Tebal 7,5mm - 8mm. Lebar 25cm",
    items: [
      { name: "DE800", src: DE800 },
      { name: "DE801", src: DE801 },
      { name: "DE802", src: DE802 },
      { name: "DE803", src: DE803 },
      { name: "DE805", src: DE805 },
      { name: "DE806", src: DE806 },
    ],
  },
  {
    title: "DE BROWN SERIES",
    caption: "Panjang 6m. Tebal 7,5mm - 8mm. Lebar 25cm",
    items: [
      { name: "DE812", src: DE812 },
      { name: "DE813", src: DE813 },
    ],
  },
  {
    title: "DE PREMIUM",
    caption:
      "Panjang  6m. Tebal 7,5mm - 8mm. Lebar 25cm (untuk DE701 dan DE711 lebar 30cm)",
    items: [
      { name: "DE701", src: DE701 },
      { name: "DE711", src: DE711 },
      { name: "DE900", src: DE900 },
      { name: "DE900W", src: DE900W },
      { name: "DE901", src: DE901 },
      { name: "DE902", src: DE902 },
      { name: "DE903", src: DE903 },
      { name: "DE904", src: DE904 },
    ],
  },
  {
    title: "DSJ",
    caption: "Panjang 4m. Tebal 8mm. Lebar 20cm",
    items: [
      { name: "DSJ00", src: DSJ00 },
      { name: "DSJ01", src: DSJ01 },
      { name: "DSJ02", src: DSJ02 },
      { name: "DSJ03", src: DSJ03 },
      { name: "DSJ09", src: DSJ04 },
      { name: "DSJ012", src: DSJ05 },
    ],
  },
  {
    title: "LIS PLAFON PVC",
    caption: "Panjang 4 Meter",
    items: [
      { name: "JX02S", src: JX02S },
      { name: "JX04", src: JX04 },
      { name: "JX04W", src: JX04W },
      { name: "JX101", src: JX101 },
      { name: "JX101W", src: JX101W },
      { name: "JX301", src: JX301 },
      { name: "JX301W", src: JX301W },
      { name: "JX302", src: JX302 },
      { name: "JX302W", src: JX302W },
      { name: "JX305", src: JX305 },
      { name: "JX305W", src: JX305W },
    ],
  },
];
export default catalogImageIndex;
