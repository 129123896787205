import JX301W from "./lis/JX301W.jpg";
import JX305 from "./lis/JX305.jpg";
import DE812 from "./plafon/DE BROWN SERIES/DE812.jpg";
import DE701 from "./plafon/DE PREMIUM/DE701.jpg";
import DE711 from "./plafon/DE PREMIUM/DE711.jpg";
import DS04 from "./plafon/DS SERIES/DS04.jpg";
import DSJ01 from "./plafon/DSJ/DSJ01.jpg";
import DSJ03 from "./plafon/DSJ/DSJ03.jpg";
import DE803 from "./plafon/WHITE SERIES/DE803.jpg";
import DE806 from "./plafon/WHITE SERIES/DE806.jpg";

const smallCatalogImageIndex = [
  JX301W,
  JX305,
  DE812,
  DE701,
  DE711,
  DS04,
  DSJ01,
  DSJ03,
  DE803,
  DE806,
];
export default smallCatalogImageIndex;
