import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import MenuBook from "@mui/icons-material/MenuBook";
import {} from "../data/link";
import { Link } from "react-router-dom";
import Home from "@mui/icons-material/Home";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  socialMediaBatam,
  socialMediaSurabaya,
  socialMediaJakarta,
  googleMapsBatam,
  googleMapsJakarta,
  googleMapsSurabaya,
} from "../data/link";
import IconButton from "@mui/material/IconButton";
import LocationOn from "@mui/icons-material/LocationOn";
const TextWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: 8,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover": {
    color: "white",
    cursor: "pointer",
  },
});
const GoogleMaps = ({ width }) => {
  return (
    <div
      style={{
        position: "relative",
        textAlign: "right",
        aspectRatio: "3 / 2",
        width: width,
      }}
    >
      <div
        style={{
          overflow: "hidden",
          background: "none!important",
          height: "100%",
          width: "100%",
        }}
      >
        <iframe
          title="google-maps"
          width="100%"
          height="100%"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=De%20Plafon%20pvc%20(PT%20MARINA%20BATAM%20SEJAHTERA)&t=&z=13&ie=UTF8&iwloc=&output=embed"
        ></iframe>
      </div>
    </div>
  );
};
const linksContent = [
  { icon: <Home fontSize="small" />, text: "Home", href: "/" },
  { icon: <MenuBook fontSize="small" />, text: "Catalog", href: "/catalog" },
];
const Footer = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box bgcolor="grey.900" mt={3}>
      <Container>
        <Grid container spacing={3} py={5}>
          <Grid item xs={12} lg={4} color="rgba(255, 255, 255, 0.7)">
            <Typography color="white" variant="h6" mb={1}>
              More About Company
            </Typography>
            <Typography variant="body2" mb={1}>
              De Plafon telah memulai usahanya sejak Januari 2016. De Plafon
              memulai usaha sebagai supplier Plafon PVC dan 3D board, kemudian
              berkembang dengan penampilan produk seperti wastafel PVC,
              Wallboard, Rumput Sintetis, Vinyl dan SPC.
            </Typography>
            <Typography variant="body2">
              De Plafon berkomitmen untuk memberikan produk yang ramah
              lingkungan, elegan, dan modern sesuai dengan kebutuhan pasar yang
              ada. Dengan kualitas produk yang selalu kita jaga sehingga kita
              dapat terus dipercaya oleh masyarakat.
            </Typography>
          </Grid>
          <Grid item xs={12} sm>
            <Typography color="white" variant="h6" mb={1}>
              Google Maps (Batam)
            </Typography>
            <GoogleMaps width="100%" />
          </Grid>
          <Grid item xs={12} sm>
            <Typography color="white" variant="h6" mb={1}>
              Contact Us
            </Typography>

            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "rgba(255, 255, 255, 0.1)",
                  color: "rgba(255, 255, 255, 0.7)",
                }}
              >
                <TabList
                  onChange={handleChange}
                  textColor="rgba(255, 255, 255, 0.7)"
                  sx={{
                    "& .MuiTabs-indicator": {
                      bgcolor: "rgba(255, 255, 255, 0.7) !important",
                    },
                    "& .MuiButtonBase-root": { textTransform: "capitalize" },
                  }}
                >
                  <Tab label="Batam" value="1" />
                  <Tab label="Surabaya" value="2" />
                  <Tab label="Jakarta" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ p: 1 }}>
                <Stack gap={1} direction="row">
                  {socialMediaBatam.map(({ icon, href }, i) => {
                    return (
                      <MuiLink
                        href={href}
                        target="_blank"
                        key={i}
                        sx={{ color: "white" }}
                      >
                        <IconButton
                          sx={{
                            color: "rgba(255, 255, 255, 0.7)",
                            "&:hover": {
                              color: "white",
                            },
                          }}
                        >
                          {icon}
                        </IconButton>
                      </MuiLink>
                    );
                  })}
                  {googleMapsBatam ? (
                    <MuiLink
                      href={googleMapsBatam.href}
                      target="_blank"
                      sx={{ color: "white" }}
                    >
                      <IconButton
                        sx={{
                          color: "rgba(255, 255, 255, 0.7)",
                          "&:hover": {
                            color: "white",
                          },
                        }}
                      >
                        <LocationOn />
                      </IconButton>
                    </MuiLink>
                  ) : null}
                </Stack>
              </TabPanel>
              <TabPanel value="2" sx={{ p: 1 }}>
                <Stack gap={1} direction="row">
                  {socialMediaSurabaya.map(({ icon, href }, i) => {
                    return (
                      <MuiLink
                        href={href}
                        target="_blank"
                        key={i}
                        sx={{ color: "white" }}
                      >
                        <IconButton
                          sx={{
                            color: "rgba(255, 255, 255, 0.7)",
                            "&:hover": {
                              color: "white",
                            },
                          }}
                        >
                          {icon}
                        </IconButton>
                      </MuiLink>
                    );
                  })}
                  {googleMapsSurabaya ? (
                    <MuiLink
                      href={googleMapsSurabaya.href}
                      target="_blank"
                      sx={{ color: "white" }}
                    >
                      <IconButton
                        sx={{
                          color: "rgba(255, 255, 255, 0.7)",
                          "&:hover": {
                            color: "white",
                          },
                        }}
                      >
                        <LocationOn />
                      </IconButton>
                    </MuiLink>
                  ) : null}
                </Stack>
              </TabPanel>
              <TabPanel value="3" sx={{ p: 1 }}>
                <Stack gap={1} direction="row">
                  {socialMediaJakarta.map(({ icon, href }, i) => {
                    return (
                      <MuiLink
                        href={href}
                        target="_blank"
                        key={i}
                        sx={{ color: "white" }}
                      >
                        <IconButton
                          sx={{
                            color: "rgba(255, 255, 255, 0.7)",
                            "&:hover": {
                              color: "white",
                            },
                          }}
                        >
                          {icon}
                        </IconButton>
                      </MuiLink>
                    );
                  })}
                  {googleMapsJakarta ? (
                    <MuiLink
                      href={googleMapsJakarta.href}
                      target="_blank"
                      sx={{ color: "white" }}
                    >
                      <IconButton
                        sx={{
                          color: "rgba(255, 255, 255, 0.7)",
                          "&:hover": {
                            color: "white",
                          },
                        }}
                      >
                        <LocationOn />
                      </IconButton>
                    </MuiLink>
                  ) : null}
                </Stack>
              </TabPanel>
            </TabContext>
            <Typography color="white" mb={1} variant="h6">
              Links
            </Typography>
            <Stack spacing={1}>
              {linksContent.map(({ icon, text, href }, i) => {
                return (
                  <Link to={href} style={{ textDecoration: "none" }} key={i}>
                    <TextWrapper>
                      {icon}
                      <Typography>{text}</Typography>
                    </TextWrapper>
                  </Link>
                );
              })}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
