import c0 from "./c0.jpg";
import c1 from "./c1.jpg";
import c2 from "./c2.jpg";
import c3 from "./c3.jpg";
import c4 from "./c4.jpg";
import modern from "./modern.jpg";
import stylish from "./stylish.jpg";
import elegant from "./elegant.jpg";
import p1 from "./p1.jpg";
import p2 from "./p2.jpg";
import p3 from "./p3.jpg";
import p4 from "./p4.jpg";
import p5 from "./p5.jpg";
import p6 from "./p6.jpg";
import p7 from "./p7.jpg";
import p8 from "./p8.jpg";
import p9 from "./p9.jpg";

export const coverImage = [c1, c2, c3, c4];
export const subCoverImage = c0;
export const optionStyleImage = [modern, stylish, elegant];
export const portfolioImage = [p1, p2, p3, p4, p5, p6, p7, p8, p9];
