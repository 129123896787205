import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Slider from "react-slick";
import { portfolioImage } from "../../assets/image/portfolio";
import Container from "@mui/material/Container";
const Portfolio = () => {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    className: "portfolio-slider",
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "ease",
    swipeToSlide: true,
    focusOnSelect: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 900,
        settings: {
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 1200,
        settings: {
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 1,
          centerPadding: "300px",
        },
      },
      {
        breakpoint: 9999,
        settings: {
          slidesToShow: 3,
          centerPadding: "100px",
        },
      },
    ],
  };
  return (
    <Box>
      <Container disableGutters>
        <Stack
          width="100%"
          height="100%"
          justifyContent="center"
          spacing={3}
          py={5}
        >
          <Typography
            component="h2"
            variant="body1"
            color="text.secondary"
            textAlign="center"
          >
            Product
          </Typography>
          <Typography
            component="h3"
            sx={{ typography: { sm: "h5", xs: "h6" } }}
            textAlign="center"
          >
            Lihatlah <b style={{ color: "#1519EA" }}> Hasil Kerja</b> Kami
          </Typography>
        </Stack>
      </Container>
      <Box
        component={Slider}
        {...sliderSettings}
        sx={{ "& .slick-slide": { p: "16px" } }}
      >
        {portfolioImage.map((image) => {
          return (
            <Box
              key={image}
              component="img"
              src={image}
              display="block"
              sx={{ cursor: "pointer", boxShadow: 2, borderRadius: 2 }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default Portfolio;
